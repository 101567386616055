.educations {
    width: 100%;
    position: relative;
}

.education {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    margin-top: 20px;
}

.education-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.education-header h3 {
    margin-bottom: 0px;
    margin-top: 0px;
}

.education-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 0px;
    margin-top: 20px;
}
