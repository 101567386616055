.sidebar {
    background-color: #FBFCFF;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 40;
}

.sidebar-options {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30%;
    width: 100%;
    margin-bottom: 20px;
}

.sidebar-logout-button {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 4px 8px;
    border: none;
    background-color: transparent;
    color: #9C9C9C;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sidebar-logout-button:hover {
    background-color: #d1d1d186;
    border-radius: 10px;
}

.sidebar-logout-image {
    width: 20px;
    height: 20px;
}

.sidebar-version {
    width: 90%;
    margin-bottom: 10px;
}

.version-button {
    border: 1px solid #00000066;
    color: #00000066;
    background: transparent;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.version-button:hover {
    background-color: #d1d1d186;
}

@media only screen and (max-width: 600px), screen and (max-height: 600px) {
    .sidebar {
        position: absolute;
        width: 100vw !important;
        height: 40px !important;
        z-index: 40;
        background-color: #9C9C9C33;
    }

    .sidebar-options {
        flex-direction: row;
        justify-content: space-evenly;
        gap: 1px;
        height: 38px;
        margin-top: 2px;
    }

    .sidebar-option {
        padding: 0;
        width: 100%;
        height: 100%;
        background-color: #FAFAFA;
        border-radius: 0;
        align-items: center;
        justify-content: center;
    }

    .option-icon {
        margin: 0;
    }

    /* .sidebar-options {
        height: calc(100vh - 100px);
    } */
}
