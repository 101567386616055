.modal-content.wide {
    max-width: 60%;
    height: max-content;
    max-height: 90vh;
}

.modal-content.wide-exp {
    max-width: 60%;
    height: max-content;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-content.wide-pdf {
    max-width: 80%;
    max-height: 90vh;
    height: 90vh;
}

.modal-content.wide-pdf iframe {
    width: 100%;
    height: 100%;
}

.modal-content.wide-feedback {
    z-index: 1101;
    height: max-content;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-content::-webkit-scrollbar {
    width: 4px;
}

.modal-content::-webkit-scrollbar-thumb {
    background-color: #dbdbdb;
    border-radius: 2px;
    transition: background-color 0.3s;
}

.modal-content::-webkit-scrollbar-thumb:hover {
    background-color: #9e9e9e;
}

.new-info-exprerience-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    color: #000000;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 15px;
}

.new-info-header .title {
    margin-left: 0px;
    margin-bottom: 0px;
}

.new-info-exprerience-container input::-webkit-input-placeholder,
.new-info-exprerience-container textarea::-webkit-input-placeholder,
.new-info-exprerience-container select::-webkit-input-placeholder {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 15px;
}

.new-info-exprerience-container input,
.new-info-exprerience-container textarea {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 15px;
    width: 100%;
    box-sizing: border-box;
}

.new-info-exprerience-container textarea {
    width: 100%;
    height: 150px;
    resize: none;
}

.new-info-experience-modal-title,
.new-info-experience-modal select {
    height: 41px;
    border: 1px solid #ccc;
    transition:
        border-color 0.3s,
        box-shadow 0.3s;
    outline: none;
}

.new-info-experience-modal-title:hover {
    border-color: #bbb;
}

.new-info-experience-modal-title:focus {
    border-color: #2684ff;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 0 0 1px #2684ff;
}

.new-info-experience-modal-time,
.new-info-experience-modal {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.new-info-experience-modal-container {
    display: flex;
    flex-direction: column;
}

.new-info-experience-modal .input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.new-info-experience-modal-label {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
}

.new-info-experience-modal-label .visibility-edit-button {
    background-color: transparent;
    position: relative;
    top: 1.5px;
    left: 1px;
}

.new-info-experience-modal-engagement {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.new-info-experience-modal-engagement > * {
    flex: 1;
}

.new-info-exprerience-container button {
    background-color: #0000000d;
    transition: background-color 0.3s ease;
    color: #00000066;
    width: auto;
    cursor: pointer;
    border: none;
    font-size: 17px;
    border-radius: 3px;
    padding: 5px;
}

.new-info-wrapper button:hover {
    border: none;
    background-color: #0000001a;
}

.new-info-wrapper button.active {
    background-color: #007aff1a;
    color: #007aff;
}

.new-info-wrapper button.active:hover {
    border: none;
    background-color: #007aff33;
}

.new-info-wrapper .dropzone {
    width: 100%;
}

.new-info-description {
    position: absolute;
    bottom: 5px;
    right: 10px;
    font-size: 10px;
    color: #00000066;
    user-select: none;
}

.description-input .red-border,
.new-info-experience-modal-container .red-border {
    border-color: #ff453a66;
    box-shadow: none;
}

.description-input .red-border:focus,
.new-info-experience-modal-container .red-border {
    border-color: #ff453a66;
    outline-color: #ff453a66;
}

.dots-button-image {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.done-button button {
    background-color: transparent;
    border: none;
    color: #007aff;
    cursor: pointer;
    text-decoration: none;
}

.done-button button:hover {
    background-color: transparent;
    text-decoration: underline;
}

@media only screen and (max-width: 600px), screen and (max-height: 600px) {
    .modal-content.wide {
        max-width: 86%;
        max-height: 80%;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .modal-content.wide-exp {
        max-width: 86%;
        max-height: 80%;
    }

    .new-info-experience-modal-time {
        flex-direction: column;
    }

    .new-info-experience-modal {
        flex-direction: column;
    }

    .new-info-experience-modal-engagement {
        flex-direction: column;
    }
}
