.loading-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fafcff;
}

.loading-text {
    position: absolute;
    top: 45%;
    font-size: 24px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.spinner {
    position: absolute;
    top: 50%;
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-top: 4px solid #000;
    animation: spin 1s linear infinite;
}

.loading-logout-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    bottom: 5%;
    left: 5%;
    background-color: #F3F3F3;
    color: #9C9C9C;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
}

.loading-logout-button:hover {
    background-color: #e7e7e7;
}

.loading-logout-button:focus {
    outline: none;
}

.logo-container {
    position: absolute;
    top: 15%;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 200px;
}

.entire-logo {
    width: 35vw;
}

.cotalent {
    margin-left: 12px;
    margin-top: -5px;
    height: 90px;
    width: auto;
}

@media only screen and (max-width: 600px), screen and (max-height: 600px) {
    .logo-container {
        top: 15%;
    }

    .cotalent {
        height: 40px;
        width: auto;
    }

    .logo-container svg {
        width: 80px;
        height: auto;
    }
}


@keyframes moveBubble1 {
    0%, 100% { transform: translate(0, 0); }
    25% { transform: translate(-23px, 22px); }
    50% { transform: translate(23px, -22px); }
    75% { transform: translate(-22px, 23px); }
}
  
#bubble1 {
    animation: moveBubble1 6s ease-in-out infinite;
}

@keyframes moveBubble2 {
    0%, 100% { transform: translate(0, 0); }
    25% { transform: translate(22px, 23px); }
    50% { transform: translate(-22px, 23px); }
    75% { transform: translate(23px, 12px); }
  }
  
#bubble2 {
    animation: moveBubble2 5s ease-in-out infinite;
}

@keyframes moveBubble3 {
    0%, 100% { transform: translate(0, 0); }
    25% { transform: translate(0px, 24px); }
    50% { transform: translate(-24px, 14px); }
    75% { transform: translate(-13px, 33px); }
  }
  
#bubble3 {
    animation: moveBubble3 4s ease-in-out infinite;
}

@keyframes moveBubble4 {
    0%, 100% { transform: translate(0, 0); }
    25% { transform: translate(23px, -23px); }
    50% { transform: translate(-23px, 23px); }
    75% { transform: translate(22px, -22px); }
  }
  
#bubble4 {
    animation: moveBubble4 7s ease-in-out infinite;
}

@keyframes moveBubble5 {
    0%, 100% { transform: translate(0, 0); }
    25% { transform: translate(-22px, 22px); }
    50% { transform: translate(22px, -22px); }
    75% { transform: translate(-21px, 12px); }
  }
  
#bubble5 {
    animation: moveBubble5 6s ease-in-out infinite;
}

@keyframes moveBubble6 {
    0%, 100% { transform: translate(0, 0); }
    25% { transform: translate(12px, -21px); }
    50% { transform: translate(0px, 21px); }
    75% { transform: translate(22px, -22px); }
  }
  
#bubble6 {
    animation: moveBubble6 5s ease-in-out infinite;
}

@keyframes moveBubble7 {
    0%, 100% { transform: translate(0, 0); }
    25% { transform: translate(-23px, -23px); }
    50% { transform: translate(23px, 23px); }
    75% { transform: translate(-22px, 22px); }
  }
  
#bubble7 {
    animation: moveBubble7 4s ease-in-out infinite;
}

@keyframes moveBubble8 {
    0%, 100% { transform: translate(0, 0); }
    25% { transform: translate(22px, 22px); }
    50% { transform: translate(22px, -22px); }
    75% { transform: translate(-23px, 23px); }
}

#bubble8 {
    animation: moveBubble8 7s ease-in-out infinite;
}

@keyframes moveBubble9 {
    0%, 100% { transform: translate(0, 0); }
    25% { transform: translate(22px, -10px); }
    50% { transform: translate(22px, 0px); }
    75% { transform: translate(23px, -20px); }
}

#bubble9 {
    animation: moveBubble9 7s ease-in-out infinite;
}

@keyframes moveBubble10 {
    0%, 100% { transform: translate(0, 0); }
    25% { transform: translate(-22px, 22px); }
    50% { transform: translate(22px, -20px); }
    75% { transform: translate(23px, -23px); }
}

#bubble10 {
    animation: moveBubble10 7s ease-in-out infinite;
}

@keyframes moveBubble11 {
    0%, 100% { transform: translate(0, 0); }
    25% { transform: translate(22px, 22px); }
    50% { transform: translate(22px, 20px); }
    75% { transform: translate(23px, 23px); }
}

#bubble11 {
    animation: moveBubble11 7s ease-in-out infinite;
}

@keyframes moveBubble12 {
    0%, 100% { transform: translate(0, 0); }
    25% { transform: translate(22px, 22px); }
    50% { transform: translate(22px, 20px); }
    75% { transform: translate(23px, 23px); }
}

#bubble12 {
    animation: moveBubble12 7s ease-in-out infinite;
}
