body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    background-color: #9c9c9c33;
}

h1 {
    font-weight: 700;
    font-size: 28px;
}

h2 {
    font-weight: 600;
    font-size: 24px;
}

h3 {
    font-weight: 500;
    font-size: 20px;
}

button {
    font-weight: 500;
    font-size: 14px;
}

textarea {
    font-family: 'Inter', sans-serif;
}

.blue {
    color: #007aff;
}

.title {
    color: #00000080;
    font-size: 16px;
    margin-left: 20px;
    margin-bottom: 15px;
    cursor: default;
    text-transform: uppercase;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(100px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(100px);
    }
}

.alert-message {
    position: fixed;
    bottom: 3%;
    right: 0;
    left: 0;
    margin: auto;
    width: 20%;
    padding: 10px;
    background-color: #fee;
    border: 1px solid #edd;
    color: #a66;
    font-size: 15px;
    text-align: center;
    border-radius: 5px;
    z-index: 1201;
    transform: translateY(100px);
}

.alert-success {
    background-color: #efe;
    border: 1px solid #ded;
    color: #9a9;
}

.alert-notice {
    background-color: #eff;
    border: 1px solid #dee;
    color: #9aa;
}

.alert-warning {
    background-color: #fdf7df;
    border: 1px solid #feec6f;
    color: #c9971c;
}

.alert-error {
    background-color: #fee;
    border: 1px solid #edd;
    color: #a66;
}

.alert-message.fadeIn {
    animation: fadeInUp 0.5s forwards;
}

.alert-message.fadeOut {
    animation: fadeOut 0.5s forwards;
}

.custom-toast {
    white-space: pre-line;
    width: 30vw;
    margin-left: -7.5vw;
}
