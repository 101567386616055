.new-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 16px;
}

.new-user > .experience {
    width: 98%;
}

.new-user-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 8px;
    box-sizing: border-box;
}

.new-user-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 2%;
    width: calc(100% - 16px);
}

.new-component {
    border: 1px solid transparent;
    box-shadow: 0px 0px 4px transparent;
    width: calc(50% - 44px);
    height: 75px;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 17px;
    font-weight: 500;
}

.valid-border {
    border: 1px solid #007AFF !important;
    box-shadow: 0px 0px 4px #007AFF33 !important;
}

.valid-border:focus {
    outline: 1px solid #007AFF !important;
}

.invalid-border {
    border: 1px solid !important;
    border-color: #FF453A66 !important;
    box-shadow: 0px 0px 4px #FF453A33 !important;
}

.invalid-border:focus {
    outline: 1px solid #FF453A !important;
}

.facultative-border {
    border: 1px solid !important;
    border-color: #FF950066 !important;
    box-shadow: 0px 0px 4px #FF950033 !important;
}

.facultative-border:focus {
    outline: 1px solid #FF9500 !important;
}

.new-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.new-info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 15px; */
}

.new-info-header-icon {
    display: flex;
    align-items: center;
    gap: 10px;
}

.new-info-header-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.new-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.new-info-container.preferedbs {
    color: #000000;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 15px;
}

.new-info-container input, .new-info-container select, .new-info-container button {
    background-color: #FFFFFF;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    width: 80%;
    font-weight: 500;
    font-size: 14px;
}

.new-info-container input::placeholder {
    color: #00000040;
}

.new-info-container select {
    height: 38px;
}

.new-info-container button {
    border: 1px solid #0000001A;
    transition: background-color 0.3s ease;
    color: #00000066;
    width: auto;
    cursor: pointer;
}

.new-info-container button:hover {
    border: 1px solid #0000001A;
    background-color: #0000001A;
}

.new-info-container button.active {
    border: 1px solid #007AFF1A;
    background-color: #007AFF1A;
    color: #007AFF;
}

.new-info-container button.active:hover {
    border: 1px solid #007AFF1A;
    background-color: #007AFF33;
}

.PhoneInput {
    gap: 10px;
}

.PhoneInputCountrySelect {
    z-index: 0;
}

.avaibility-input::placeholder {
    font-weight: 600;
    font: 'Inter', sans-serif;
    font-size: 16px;
    color: hsl(0, 0%, 60%) !important;
}
