.jd-creation {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
    padding: 10px;
    width: 100%;
}

.jd-multiplpe-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
}

.jd-info-card {
    background-color: #ffffff;
    width: calc(100% - 36px);
    height: 75px;
    border-radius: 5px;
    border: 1px solid #007aff;
    padding: 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.jd-card-header {
    font-weight: 500;
}

.jd-card-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    height: 38px;
}

.jd-card-container input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    width: 80%;
    font-weight: 500;
    font-size: 14px;
}

.jd-draft {
    padding: 10px;
    width: 100%;
}

.jd-chat {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 7px;
    height: calc(100% - 20px);
}

.jd-chat-messages {
    height: 100%;
    overflow-y: auto;
}

.jd-chat-messages::-webkit-scrollbar {
    width: 4px;
}

.jd-chat-messages::-webkit-scrollbar-thumb {
    background-color: #dbdbdb;
    border-radius: 2px;
    transition: background-color 0.3s;
}

.jd-chat-messages::-webkit-scrollbar-thumb:hover {
    background-color: #9e9e9e;
}

.jd-draft-input {
    margin-top: 10px;
    border-radius: 6px;
    border-color: #ccc;
    resize: none;
    height: 100px;
    padding: 5px;
    font-size: 15px;
}

.jd-chat-button {
    position: absolute;
    bottom: 13px;
    right: 18px;
}

.jd-button-img {
    transition: background-color 0.3s;
    padding: 5px;
    border-radius: 6px;
}

.jd-button-img:hover {
    cursor: pointer;
    background-color: #dfdfdf;
}

.jd-message-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.jd-message-container.user {
    justify-content: flex-end;
}

.jd-message-container.assistant {
    justify-content: flex-start;
}

.jd-message {
    position: relative;
    padding: 10px;
    margin: 4px 10px;
    max-width: 80%;
}

.jd-message.assistant {
    align-self: flex-start;
    background-color: #e5e5ea;
    white-space: pre-wrap;
    border-radius: 10px 10px 10px 0;
}

.jd-message.assistant::before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: -8px;
    border-width: 10px 10px 0 0;
    border-style: solid;
    border-color: transparent #e5e5ea transparent transparent;
}

.jd-message.user {
    align-self: flex-end;
    background-color: #007bff;
    color: white;
    border-radius: 10px 10px 0 10px;
}

.jd-message.user::before {
    content: '';
    position: absolute;
    bottom: 0px;
    right: -5px;
    border-width: 10px 0 0 10px;
    border-style: solid;
    border-color: transparent transparent transparent #007bff;
}

.jd-finalize {
    padding: 0 17px;
    width: calc(100% - 34px);
}

.jd-finalize-block {
    width: 100%;
}

.jd-finalize-block:last-child {
    padding-bottom: 20px;
}

.jd-finalize-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: calc(100% - 40px);
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    border: 1px solid #9c9c9c33;
    transition: background-color 0.3s;
}

.jd-finalize-content.editable {
    font-weight: 500;
}

.jd-finalize-content.editable:hover {
    cursor: pointer;
    background-color: #f5f5f5;
}

.jd-finalize-content-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
    gap: 20px;
}

.jd-finalize-content-item span:first-child {
    flex-shrink: 0;
    font-weight: 500;
}

.jd-finalize-content-item span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
