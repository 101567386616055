.education-entry {
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-bottom: 0;
    border-radius: 7px;
}

.entry-editing {
    cursor: pointer;
    will-change: transform;
    transition: transform 0.3s ease;
}

.entry-editing:hover {
    transform: scale(1.01);
    background-color: #007AFF0D;
}

.education-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.education-image-name-container {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 50%;
}

.education-title {
    font-size: 25px;
    margin-right: 10px;
    margin-top: 0;
    margin-bottom: 0px;
}

.education-title span {
    font-weight: 400;
    font-size: 20px;
}

.education-grade {
    margin-bottom: 6px;
}

.education-location {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 6px;
}

.education-location span {
    font-weight: 400;
}

.education-time {
    font-weight: 500;
    margin-bottom: 10px;
}

.education-description {
    white-space: pre-line;
    color: #000000;
}

.education-field {
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 6px;
}