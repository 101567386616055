.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
}

.modal-content {
    z-index: 1100;
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 70%;
    max-width: 500px;
}

.modal-content span {
    font-weight: 500;
    font-size: 19px;
}

.modal-done-button {
    position: absolute;
    bottom: 25px;
    right: 13px;
    padding: 8px 16px;
    background-color: transparent;
    color: #007AFF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.modal-done-button:hover {
    background-color: rgb(230, 230, 230);
}

@media only screen and (max-width: 600px), screen and (max-height: 600px) {
    .modal-content {
        width: 90%;
    }
}
