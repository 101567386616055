.highlight {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
}

.highlight-container {
    margin-top: 15px;
    display: flex;
    flex-direction: column; 
    gap: 12px;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    border: 1px solid transparent;
}

.highlight-label {
    background-color: #60C6D2;
    color: #FFFFFF;
}

.highlight-label, .badge {
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: 500;
    cursor: default;
}

.highlight-row-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
}

.badge {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #006d76;
    background-color: #b4f3f7;
    transition: background-color 0.3s;
}

.badge:hover {
    background-color: #a8e2e6;
}

.skill-name {
    flex-grow: 1;
    white-space: nowrap;
}

.close-icon {
    flex-shrink: 0;
    height: 100%;
    border: none;
    background: none;
    padding: 0;
    margin-left: 5px;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.2s;
}

.close-icon:hover {
    background-color: #ff5c00B3;
}

.highlight-close {
    height: 100%;
    width: auto;
}

.highlight-add-btn {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    position: relative;
}

.highlight-add-icon {
    height: 20px;
    width: auto;
    cursor: pointer;
}

.creatable-container {
    width: 200px;
    height: 21px;
    padding: 4px 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: absolute;
    left: -100px;
    bottom: 30px;
    z-index: 1005;
    border-radius: 3px;
}

.creatable-container:focus {
    border-color: #007bff; /* Couleur de bordure lors de la mise au point */
    outline: none; /* Supprimer l'outline par défaut */
    box-shadow: 0 2px 4px rgba(0, 123, 255, 0.25); /* Ombre plus prononcée lors de la mise au point */
}
