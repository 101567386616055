.profile-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.profile-picture {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    transition: filter 0.3s ease;
    background-color: #d9d9d933;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-picture-img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
}

.profile-picture:hover {
    filter: brightness(0.7);
}

.profile-plus-svg img {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}
