.prefered {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
}

.prefered-data-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 50%;
}

.prefered-data-wrapper-input {
    width: 50%;
}

.prefered-container {
    margin-top: 15px;
    display: flex;
    flex-direction: column; 
    gap: 12px;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    border: 1px solid transparent;
}

.prefered-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.prefered-title {
    color: #2e2e2e;
    font-weight: 500;
}

.prefered-data, .prefered-input {
    font-size: 14px;
    width: 100%;
    height: 100%;
}

.prefered .prefered-input {
    border: none;
    background-color: transparent;
    outline: none;
    padding: 0;
    margin: 0;
    margin-bottom: 0px;
    border-bottom: 1px solid #555;
}

.prefered-input:focus {
    border-bottom: 2px solid #555; /* slightly thicker border when focused */
}

.edit-icon {
    width: 27px;
    height: 27px;
    cursor: pointer;
    margin-right: -4px;
}

.react-datepicker-wrapper {
    width: 100%;
}
