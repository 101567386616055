.company-card {
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: '0 2px 4px rgba(0,0,0,0.1)';
    background-color: #ffffff;
    width: 100%;
}

.company-card.selected {
    background-color: #f5f5f5;
}

.company-card-infos {
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    transition: background-color 0.3s;
}

.company-card-infos:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

.company-card-infos-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
