.employee-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    position: relative;
}

.employee-card img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 16px;
}

.employee-info {
    flex: 1;
}

.employee-info p {
    margin: 4px 0;
}

.employee-role {
    margin-left: auto;
    font-weight: bold;
}

.select-container {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 10px;
    margin-left: auto;
}

.select-container img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 0;
    padding: 4px;
    transition: background-color 0.3s;
}

.select-container img:hover {
    background-color: #e2e2e2;
}

.spinner {
    margin-right: 10px;
}

.add-employee-btn {
    padding: 8px 16px;
    margin: 20px auto;
    display: block;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.add-employee-btn:hover {
    border: 1px solid #ddd !important;
    background-color: #0056b3;
}

@keyframes blink {
    0%,
    100% {
        opacity: 0.2;
    }
    20% {
        opacity: 1;
    }
    40% {
        opacity: 0.2;
    }
    60% {
        opacity: 1;
    }
    80% {
        opacity: 0.2;
    }
}

.dots {
    display: inline-block;
    margin-left: 5px;
    font-size: 20px;
    animation: blink 1.4s infinite both;
}

.dots span {
    animation-delay: -0.32s;
}

.dots span:nth-child(2) {
    animation-delay: -0.16s;
}
