.language-entry {
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-bottom: 0;
    border-radius: 7px;
}

.language-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
}

.language-title {
    font-size: 21px;
    font-weight: 500;
}