.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    height: 113px;
    margin-bottom: 1px;
}

.cotalent-logo {
    flex: 1;
    display: flex;
    justify-content: center;
}

.cotalent-logo img {
    height: 40px;
    width: 250px;
    cursor: pointer;
}

.alpha span {
    border: 1px solid #007AFF;
    box-shadow: 0px 0px 3px #007AFF;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    color: #007AFF;
    padding: 1px 8px;
}

.header-navigation {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.profile-text {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 25px;
}

.hn-buttons-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.hn-buttons-container .hn-button-blue {
    position: relative;
    cursor: pointer;
    padding: 3px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
    width: 100%;
    border: 1px solid #007AFF;
    background-image: linear-gradient(to right, #007AFF, #30B0C7);
    opacity: 1;
}

.hn-buttons-container .hn-button-blue::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    opacity: 0;
    border-radius: inherit; 
    transition: opacity 0.5s ease;
}

.hn-buttons-container .hn-button-blue:hover::after {
    opacity: 0.25;
}

.hn-buttons-container .hn-button-white {
    position: relative;
    cursor: pointer;
    padding: 3px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    width: 100%;
    border: 1px solid #007AFF;
    background-color: #FFFFFF;
    opacity: 1;
    transition: background-color 0.5s ease;
}

.hn-buttons-container .hn-button-white::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-image: linear-gradient(to bottom right, #007AFF, #30B0C7);
    border-radius: inherit;
    z-index: -1;
}

.hn-buttons-container .hn-button-white:hover {
    background-color: #ffffffbb;
}

.landing-body .hn-buttons-container .hn-button-white.no-anim:hover {
    background-color: #ffffff;
}

.landing-body .hn-buttons-container .hn-button-blue.no-anim:hover::after {
    opacity: 0;
}

.header-profile {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-profile-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.text-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 15px;
    gap: 15px;
    width: 100%;
    cursor: pointer;
}

.text-buttons-container span {
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    text-align: right;
}

.text-buttons-container img {
    width: 10px;
    height: 10px;
}

.buttons-container {
    display: flex;
    gap: 10px;
}

.header-profile-container .hn-button-white span {
    background: linear-gradient(to bottom right, #007AFF, #30B0C7);
    font-weight: 500;
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 18px;
    color: transparent;
}

.header-profile-container .hn-button-white {
    border-radius: 10px;
    border-width: 2px;
}

@media only screen and (max-width: 600px), screen and (max-height: 600px) {
    .header {
        flex-direction: column;
        height: 100px;
    }

    .up-header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 15px;
        width: 50%;
    }

    .cotalent-logo img {
        width: 100px;
    }

    .alpha span {
        font-size: 8px;
    }

    .profile-text {
        display: none;
    }

    .text-buttons-container {
        display: none;
    }

    .profile-picture-img {
        width: 50px;
        height: 50px;
    }
}
