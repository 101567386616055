.description-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.desciption-spinner {
    margin-bottom: 5px;
}

.description-first-time {
    display: flex;
    align-items: center;
    justify-content: center;
}

.description-first-time button {
    margin-bottom: 10px !important;
    font-size: 17px !important;
    border: none !important;
}

.chat-container {
    display: flex;
    flex-direction: column;
    height: 300px;
    overflow-y: auto;
    border: 1px solid #ff453a66;
    border-radius: 5px;
    width: 100%;
}

.description-title-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
}

.generate-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-left: 10px;
    width: calc(100% - 20px);
}

.modal-content .generate-description .description-yes {
    color: #007aff;
    cursor: pointer;
}

.modal-content .generate-description .description-no {
    color: #ff453a;
    cursor: pointer;
}

.modal-content .generate-description span {
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    color: #000000;
}

.description-title-button label {
    margin-top: 3px;
    height: 23px;
}

.chat-container-input {
    width: 100%;
}

.chat-container textarea,
.chat-container-input textarea {
    height: 100%;
    width: 100%;
    resize: none;
    white-space: pre-wrap;
}

.new-info-exprerience-container .chat-container-input textarea {
    height: 83px;
    max-height: 200px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.textarea-send-container {
    position: relative;
}

.new-info-exprerience-container .chat-input-send-container {
    position: absolute;
    right: 10px;
    bottom: 5px;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.new-info-exprerience-container .chat-input-send {
    display: flex;
    background-color: transparent;
}

.new-info-exprerience-container .chat-input-send-container span {
    color: #00000080;
    font-size: 12px;
}

.chat-input-send-container .hn-buttons-container button {
    border: none;
}

.chat-input-send img {
    width: 22px;
    height: 22px;
}

.description-input textarea {
    width: 100%;
    height: 300px;
}

.message {
    position: relative;
    padding: 10px;
    margin: 4px 10px;
    max-width: 80%;
}

.message.assistant {
    align-self: flex-start;
    background-color: #e5e5ea;
    white-space: pre-wrap;
    border-radius: 10px 10px 10px 0;
}

.message.assistant::before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: -8px;
    border-width: 10px 10px 0 0;
    border-style: solid;
    border-color: transparent #e5e5ea transparent transparent;
}

.message.user {
    align-self: flex-end;
    background-color: #007bff;
    color: white;
    border-radius: 10px 10px 0 10px;
}

.message.user::before {
    content: '';
    position: absolute;
    bottom: 0px;
    right: -5px;
    border-width: 10px 0 0 10px;
    border-style: solid;
    border-color: transparent transparent transparent #007bff;
}

.response-options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    gap: 50px;
}

.message-container.user {
    justify-content: flex-end;
}

.message-container.assistant {
    justify-content: flex-start;
}

.message-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.message-container.user button {
    padding: 10px;
    margin: 4px 10px;
    border-radius: 10px;
    max-width: 80%;
}

.message-container.user button:hover {
    background-color: #007bffb0;
}

.edit-message-icon {
    cursor: pointer;
    height: 15px;
    align-items: flex-end;
}

.typing-animation-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.typing-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -5px;
}

.bubble {
    width: 8px;
    height: 8px;
    background-color: #9b9b9b;
    border-radius: 50%;
    margin: 0 2px;
    /* Animation */
    animation: rise 2s ease-in-out infinite;
}

.bubble:nth-child(2) {
    animation-delay: 0.2s;
}

.bubble:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes rise {
    0%,
    100% {
        transform: translateY(0);
        opacity: 1;
    }
    30% {
        transform: translateY(-4px);
        opacity: 0.5;
    }
}

.feedback-message p {
    margin: 13px 0px 0px 0px;
    color: #6e6e73;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
}

.modal-content .feedback-message span {
    color: #007aff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
}
