.profile {
    width: 95%;
}

.profile-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
}

/* .profile h3 {
    margin-bottom: 15px;
    margin-left: 20px;
    color: #00000080;
    font-size: 16px;
} */

.progress-bar-wrapper {
    display: flex;
    justify-content: space-between;
    width: 97%;
    padding: 10px 0;
    color: #9C9C9C;
}

.line-blue {
    background-color: #007AFF;
}

.progress-line {
    width: 100%;
    height: 2px;
    background-color: #9C9C9C;
    position: relative;
}

.progress-bar-wrapper span {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-left: 8px;
    color: #FFFFFF;
    border-radius: 50%;
    background-color: #9c9c9c81;
    padding: 3px;
}

.step {
    position: relative;
    text-align: center;
    padding: 10px 0;
    border-radius: 20px;
    cursor: pointer;
}

.active {
    color: #007AFF;
}

.active span {
    background-color: #007AFF;
}

@media only screen and (max-width: 600px), screen and (max-height: 600px) {
    .progress-bar-wrapper {
        justify-content: center;
    }
}
