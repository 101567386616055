.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('https://storage.googleapis.com/cotalent-profile-picture/muriel-liu-PhqZtPwsoRY-unsplash.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.login-form {
    background-color: #ffffff;
    color: #7b7b7b;
    font-size: 13px;
    font-weight: 500;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 650px;
}

.entire-logo {
    cursor: pointer;
}

.form-content .entire-logo {
    width: 180px;
}

.cotalent-login-logo {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.form-title {
    color: #303030;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
}

.form-title {
    position: absolute;
    top: 0;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
}

.form-title.visible {
    opacity: 1;
    visibility: visible;
}

.forgot-password {
    transition:
        opacity 0.3s,
        transform 0.3s;
    opacity: 1;
    transform: translateY(0);
}

.forgot-password.signup {
    opacity: 0;
    transform: translateY(0px);
}

.forgot-password.password {
    opacity: 0;
    transform: translateY(0px);
}

.forgot-password.hidden {
    opacity: 0;
    transform: translateY(20px);
}

.title-container {
    position: relative;
    height: 40px;
}

.login-container .input-container {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
}

.toggle-password-visibility {
    position: absolute;
    right: -15px;
    top: 54%;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    cursor: pointer;
}

.toggle-password-visibility img {
    width: 22px;
}

.border-label-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s;
}

.border-label-input::placeholder {
    color: #0000004d;
    font-weight: 300;
    font-size: 13px;
}

.border-label-input:focus {
    border-color: #007bff;
}

.border-label-input:focus + .border-label,
.border-label-input:not(:placeholder-shown) + .border-label {
    top: -7px;
    left: 15px;
    font-size: 12px;
    background-color: #fff;
    padding: 0 10px;
}

.border-label {
    position: absolute;
    top: 10px;
    left: 10px;
    transition: all 0.3s;
    background-color: #fff;
    padding: 0 5px;
}

@keyframes showContent {
    0% {
        max-height: 0;
        overflow: visible;
    }
    100% {
        max-height: 200px;
        overflow: visible;
    }
}

@keyframes hideContent {
    0% {
        max-height: 200px;
        overflow: visible;
    }
    99% {
        overflow: visible;
    }
    100% {
        max-height: 0;
        overflow: hidden;
    }
}

.form-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: calc(100% - 20px);
}

.form-buttons-signup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
}

.form-buttons-signup {
    animation: hideContent 0.5s forwards;
}

.form-buttons-signup.active {
    animation: showContent 0.5s forwards;
}

.form-buttons-password {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
}

.btn-email,
.btn-signup {
    position: relative;
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
    z-index: 500;
}

.btn-email {
    background-color: #007aff;
    color: white;
    font-weight: 700;
    font-size: 17px;
}

.btn-signup {
    background-color: #28a745;
    color: white;
}

.social-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    gap: 25px;
}

.btn-google,
.btn-facebook {
    width: 100%;
    color: #7b7b7b;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 30px 10px 30px;
    background-color: #ffffff;
    border: 1px solid #7b7b7b;
    border-radius: 5px;
    gap: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-google:hover,
.btn-facebook:hover {
    background-color: #f2f2f2;
}

.or-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    font-size: 18px;
    position: relative;
    width: 100%;
}

.or-section span {
    z-index: 1;
    background-color: #fff;
    padding: 0 10px;
}

.or-section::before,
.or-section::after {
    content: '';
    position: absolute;
    top: 50%;
    width: calc(50% - 50px);
    height: 1px;
    background-color: #e3e3e8;
}

.or-section::before {
    left: 0;
}

.or-section::after {
    right: 0;
}

.sign-in-link {
    color: #007bff;
    cursor: pointer;
    transition: color 0.3s;
}

.sign-in-link:hover {
    color: #007bffd7;
    text-decoration: underline;
}

.sign-in-link.hidden {
    cursor: default;
}

@media only screen and (max-width: 600px), screen and (max-height: 600px) {
    .login-container {
        overflow: hidden;
    }

    .login-form {
        padding: 20px 18px;
        width: 80%;
    }

    .form-title.visible {
        font-size: 22px;
    }

    .title-container {
        margin-bottom: 12px;
    }

    .btn-facebook,
    .btn-google {
        padding: 10px;
    }
}

@media only screen and (max-height: 600px) {
    .login-form {
        overflow-x: auto;
        padding: 15px 18px;
    }

    .form-title.visible {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .title-container {
        margin-bottom: 0px;
    }

    .form-buttons {
        gap: 0px;
    }

    .btn-email {
        margin-top: 0px;
    }

    .or-section {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .form-content p {
        margin-top: 8px;
        margin-bottom: 5px;
    }

    .social-buttons {
        margin-top: 5px;
    }
}
