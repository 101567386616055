.main-content::-webkit-scrollbar {
    width: 4px;
}

.main-content::-webkit-scrollbar-thumb {
    background-color: #dbdbdb;
    border-radius: 2px;
    transition: background-color 0.3s;
}

.main-content::-webkit-scrollbar-thumb:hover {
    background-color: #9e9e9e;
}

@media (max-width: 767px) {
    .sidebar {
        position: relative;
        width: 100%;
    }

    .main-content-header {
        flex-direction: column;
    }

    .main-content-header > * {
        width: 90%;
    }

    .main-content {
        position: relative;
        overflow-y: visible;
        margin-left: 2%;
        width: 96%;
        height: 100%;
    }
}

.route-section {
    display: flex;
    justify-content: center;
    width: 100%;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
}
