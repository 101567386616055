.landing {
    font-family: 'Poppins', sans-serif;
    width: 100vw;
    height: 100vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #FFFFFF;
}

.landing::-webkit-scrollbar {
    display: none;
}

.landing-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;
    margin-left: 30px;
    margin-right: 30px;
}

.landing-header .hn-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 30px;
}

.landing-header .hn-buttons-container button {
    font-weight: 600;
    white-space: nowrap;
}


.landing-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}



.hero-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    gap: 15%;
    margin-bottom: 30px;
}

.hero-text h1 {
    font-weight: 700;
    font-size: 70px;
    margin-bottom: 20px;
}

.hero-text p {
    font-weight: 500;
    font-size: 20px;
    color: #00000099;
    margin-bottom: 35px;
}

.landing-body .hn-buttons-container button {
    font-weight: 600;
    color: #FFFFFF;
    white-space: nowrap;
    padding: 7px 0;
}

hr {
    border: none;
    height: 1px;
    background-color: #9C9C9C33;
    margin: 0;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
}

.features-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin-bottom: 30px;
    color: #000000CC;
}

.feature-animation.slide-in {
    opacity: 0;
    transform: translateX(100px);
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}

.feature-animation.slide-in.in-view {
    opacity: 1;
    transform: translateX(0);
}


.feature-animation.slide-up {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}

.feature-animation.slide-up.in-view {
    opacity: 1;
    transform: translateY(0);
}

.features-header h1 {
    font-weight: 700;
    font-size: 36px;
}

.features-body {
    display: flex;
    flex-direction: row;
    gap: 13%;
    width: 100%;
}

.feature {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.feature h2 {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 0px;
}

.feature-entry {
    margin-bottom: 8px;
}

.feature h3 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 0px;
}

.feature span {
    color: #00000099;
    font-weight: 400;
    font-size: 17px;
}

.features-section .hn-buttons-container button {
    margin-top: 30px;
    padding: 7px 30px;
}

@media only screen and (max-width: 600px) {
    .landing-header {
        margin: 0;
        padding: 20px 10px;
        gap: 20px;
        flex-direction: column;
    }

    .cotalent-login-logo {
        justify-content: center;
    }

    .cotalent-login-logo img {
        width: 200px;
    }

    .hero-image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .hero-image img {
        width: 90%;
    }

    .hero-section {
        flex-direction: column;
        gap: 30px;
    }

    .hero-text h1 {
        margin-top: 20px;
        font-size: 42px;
        margin-bottom: 0px;
    }

    .hero-text p {
        font-size: 20px;
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .features-section {
        width: 90%;
    }

    .features-body {
        gap: 15px;
    }
}

@media only screen and (max-height: 600px) {
    .hero-text h1 {
        margin-top: 20px;
        font-size: 42px;
        margin-bottom: 0px;
    }

    .hero-text p {
        font-size: 18px;
        margin-top: 5px;
        margin-bottom: 20px;
    }

    .hero-image img {
        width: 100%;
    }

    .hero-section {
        gap: 20px;
    }

    .features-section {
        width: 90%;
    }

    .features-body {
        gap: 8%;
    }
}