.experiences {
    width: 100%;
    position: relative;
}

.experience {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}

.experience-add-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.experience-add-title h3 {
    margin-bottom: 0px;
    margin-top: 0px;
}

.add-experience {
    height: 15px;
    margin-right: 23px;
    cursor: pointer;
}

.experience-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 10px;
    margin-bottom: 0px;
    margin-top: 20px;
    border: 1px solid transparent;
}
