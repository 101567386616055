.policies-container {
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    margin-top: 20px;
    margin-left: 100px;
    width: calc(100% - 200px);
}

.policies-title {
    font-size: 60px;
}

.policies-subtitle {
    font-size: 40px;
    font-weight: 400;
    margin-top: 20px;
}

.policies-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.policies-button {
    width: 49%;
    height: 300px;
    background-color: transparent;
    border: 1.5px solid #0000004d;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.policies-button:hover {
    background-color: #0000000d;
}

.policies-button-title {
    margin-top: 0;
}

.policies-button-text {
    font-size: 18px;
}

.policies-subtitle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
}

.policies-spinner {
    margin-bottom: 15px;
}
