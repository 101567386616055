.secondary-content {
    top: 0px;
    right: 0;
    bottom: 0;
    width: 25%;
    background-color: #FBFCFF;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 40;
}

.secondary-content::-webkit-scrollbar {
    width: 4px;
}

.secondary-content::-webkit-scrollbar-thumb {
    background-color: #dbdbdb;
    border-radius: 2px;
    transition: background-color 0.3s;
}

.secondary-content::-webkit-scrollbar-thumb:hover {
    background-color: #9e9e9e;
}

.secondary-content-feedback {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
    height: 95%;
}

.secondary-content-feedback button {
    font-family: 'Inter', sans-serif;
    border: none;
    background-color: transparent;
    color: #007AFF;
    padding: 10px;
    border-radius: 50px;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.secondary-content-feedback button:hover {
    background-color: #ebebeb;
}

.secondary-content-pdf-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}

.secondary-content-title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.secondary-content-pdf {
    height: 90%;
    width: 95%;
}

.secondary-content-pdf iframe {
    width: 100%;
    height: 100%;
}

.secondary-content-title {
    gap: 10px;
}

.secondary-content-title button {
    border: none;
    background-color: transparent;
}

.reload-icon {
    width: 18px;
    height: 18px;
    margin-top: 4px;
    margin-left: 4px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.reload-icon:hover {
    transform: rotate(90deg);
}

.secondary-content-title span {
    font-weight: 600;
    font-size: 13px;
    margin-top: 18px;
    margin-bottom: 17px;
    border: 1px solid #00000080;
    border-radius: 5px;
    padding: 0px 5px;
    cursor: pointer;
    color: #00000080;
}

.secondary-content-title .refresh-active {
    color: #007AFF;
    border-color: #007AFF;
}

@media only screen and (max-width: 600px), screen and (max-height: 600px) {
    .hamburger-button-secondary {
        display: block;
        position: fixed;
        top: 110px;
        right: 10px;
        z-index: 100;
        border: none;
        border-radius: 8px;
        background-color: #0000000D;
    }

    .hamburger-button-secondary:hover {
        cursor: pointer;
        background-color: #0000001A;
    }

    .hamburger-button-secondary img {
        width: 30px;
        height: 30px;
    }

    .secondary-content {
        position: fixed;
        margin-top: 101px;
        width: 70vw !important;
        transform: translateX(100%);
        transition: transform 0.3s ease;
        height: calc(100vh - 101px - 41px) !important;
    }

    .secondary-content.open {
        transform: translateX(0); /* Glisse en vue quand ouvert */
    }
}
