/* Stylise le conteneur du DatePicker */
.react-datepicker-wrapper {
    /* À ajuster selon vos préférences */
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
}

/* Stylise le champ de saisie à l'intérieur du DatePicker */
.react-datepicker__input-container input {
    width: 100%;
    padding: 5px;
    border: none;
    background: transparent;
    outline: none;
    font-size: 16px;
}

/* Stylise le calendrier du DatePicker */
.react-datepicker {
    border: none;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
}

.react-datepicker__current-month {
    font-size: 16px;
}

.react-datepicker__day--selected,
.react-datepicker__day:hover {
    background-color: #007bff;
    color: white;
}

.react-datepicker__day--disabled {
    color: #ccc;
}

.info-icon {
    width: 18px;
}

.loading-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
