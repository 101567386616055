.sidebar-option {
    border-radius: 8px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    height: 50px;
    width: 85%;
    margin-bottom: 3px;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 18px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.sidebar-option:hover {
    background-color: #f5f5f5;
}

.option-icon {
    height: 24px;
    width: 24px;
    margin-left: 20%;
    transition: filter 0.3s ease;
}

.sidebar-option.selected {
    color: #007AFF;
}

.sidebar-option.selected .option-icon {
    filter: invert(21%) sepia(99%) saturate(2578%) hue-rotate(220deg) brightness(96%) contrast(101%);
}
