.finalize {
    width: 96%;
}

.not-editing {
    border: 1px solid #9C9C9C33;
}

.editing-blue {
    border: 1px solid #007AFF;
    box-shadow: 0px 0px 4px #007AFF66;
}

.editing-orange {
    border: 1px solid #FF950066;
    box-shadow: 0px 0px 4px #FF950066;
}

.editing-red {
    border: 1px solid #FF453A;
    box-shadow: 0px 0px 4px #FF453A66;
}

.editing-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin-top: 8px;
    margin-left: 20px;
    border: none;
    box-shadow: none;
    font-weight: 600;
    font-size: 11px;
}

.editing-message.editing-blue {
    color: #007AFF;
}

.editing-message.editing-orange {
    color: #FF9500;
}

.editing-message.editing-red {
    color: #FF453A;
}