.languages {
    width: 100%;
    position: relative;
}

.language {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    padding-bottom: 40px;
}

.language-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.language-header h3 {
    margin-bottom: 0px;
    margin-top: 0px;
}

.language-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    padding-top: 5px;
    padding-bottom: 10px;
    margin-bottom: 0px;
    margin-top: 10px;
}
