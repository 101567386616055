.visibility-edit-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    z-index: 10;
    height: 20px;
}

.visibility-edit-button img {
    height: 20px;
    width: 20px;
    z-index: 10;
}

.visibility-edit-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 128, 255, 0.5);
    border-radius: 15%;
}

.visibility-edit-button {
    position: relative;
    display: inline-block; 
    cursor: pointer;
    z-index: 2;
}

.tooltip {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease; /* Add a transition */
    background-color: white;
    color: grey;
    text-align: center;
    border-radius: 6px;
    border: 1px solid grey; /* This will show the border */
    padding: 3px 7px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* position above the icon */
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap; /* stops it from breaking into multiple lines */
    font-size: 15px;

    /* arrow at the bottom of tooltip */
    &:after {
        content: '';
        position: absolute;
        top: 100%; /* at the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: grey transparent transparent transparent;
    }
}

.tooltip-blue {
    color: blue;
    border-color: blue;

    &:after {
        border-color: blue transparent transparent transparent;
    }
}

.visibility-edit-button:hover + .tooltip,
.tooltip:hover {
    opacity: 1;
    visibility: visible;
}
