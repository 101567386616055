.year-buttonn {
    border-radius: 6px;
    border: 1px solid rgb(189, 189, 189);
    background-color: white;
    color: black;
    cursor: pointer;
    text-align: center;
    padding-right: 3px;
    white-space: nowrap;
    margin-top: -0px;
}

.year-buttonn select {
    border: none;
    background: transparent;
    appearance: none;
    outline: none;
    font-size: 13.5px;
    cursor: pointer;
    color: #2c6dff;
    margin-left: 8px;
    width: 40px;
}

.year-buttonn:hover {
    background-color: #f5f5f5;
    background-image: none;
}

.year-buttonn select:last-child {
    width: 35px;
}