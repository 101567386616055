.delete-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}

.delete-button img {
    height: 17px;
    width: 17px;
}

.delete-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 128, 255, 0.5);
    border-radius: 15%;
}

.delete-button {
    position: relative;
    display: inline-block; 
    cursor: pointer;
    z-index: 2;
}

.remove-tooltip {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease; /* add a transition */
    background-color: white;
    color: red;
    border-color: red;
    text-align: center;
    border-radius: 6px;
    border: 1px solid red; /* This will show the border */
    padding: 3px 7px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* position above the icon */
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap; /* stops it from breaking into multiple lines */
    font-size: 15px;

    /* arrow at the bottom of tooltip */
    &:after {
        content: '';
        position: absolute;
        top: 100%; /* at the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: red transparent transparent transparent;
    }
}

.delete-button:hover .remove-tooltip {
    opacity: 1;
    visibility: visible;
}

.delete-button-hidden {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}

.delete-button-raised {
    position: relative;
    top: -15px;
}

.delete-modal-overlay {
    position: fixed;
    z-index: 1500;
}

.delete-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 400px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.delete-modal h2 {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}

.modal-buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.modal-buttons button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: 600;
    border-radius: 5px;
}

.modal-buttons button:first-child {
    background-color: #ffffff;
    color: grey;
    border: 1px solid grey;
}

.modal-buttons button:first-child:hover {
    background-color: #d6d6d6;
}

.modal-buttons button:last-child {
    background-color: #f44336; /* Rouge */
    color: #ffffff;
}

.modal-buttons button:last-child:hover {
    background-color: #c02b2b;
}
