.editable-section-textarea {
    border: none;
    outline: none;
    overflow: hidden;
    white-space: pre-wrap; 
    word-wrap: break-word;
    height: auto;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 15px;
    padding-top: 10px;
    padding-bottom: 18px;
    margin-left: -5px;
    margin-right: -5px;
    margin-top: -10px;
    margin-bottom: -8px;
}

.editable-section-textarea:hover, .experience-textarea:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.experience-textarea {
    width: 96%;
    border: none;
    outline: none;
    resize: none;
    overflow: hidden;
    white-space: pre-wrap; 
    word-wrap: break-word;
    height: auto;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 15px;
    padding-top: 3px;
    padding-left: 8px;
    padding-bottom: 20px;
    margin-top: -8px;
}

.editable-section-container {
    margin-left: 10px;
    margin-right: 10px;
    white-space: pre-line;
}

.editable-section-container, .editable-section-textarea {
    font-size: 16px;
}