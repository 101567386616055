.add-section {
    margin-right: 15px;
}

.add-section-icon-menu {
    height: 18px;
}

.add-section-icon {
    height: 18px;
    cursor: pointer;
    padding: 8px;
    margin-right: -8px;
    border-radius: 50%;
    transition: background-color 0.2s ease-out;
    width: 18px;
}

.add-section-icon:hover {
    background-color: #eeeeee;
}

.dropdown {
    position: absolute;
    display: none;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.add-section .dropdown button {
    z-index: 9999;
    display: block;
    width: 100%;
    text-align: left;
    padding: 10px 15px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 12px;
    color: black;
    white-space: nowrap;
}

.dropdown button:hover {
    background-color: #f5f5f5;
}
