.experience-company-container {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 10px;
    padding-bottom: 0px;
    border-radius: 7px;
    margin-top: 10px;
}

.experience-company-container span {
    color: #000000;
    font-style: italic;
    font-weight: 400;
    margin-bottom: 10px;
}

.company-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
}

.company-wrapper h3 {
    font-size: 23px;
    margin-bottom: 0;
}

.company-favicon {
    width: 25px;
    height: 25px;
}

.company-image-name-container {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 50%;
}

.company-website {
    background-color: #2c6dff;
    color: white;
    padding: 3px 8px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
}

.company-website-input {
    background-color: rgba(230, 230, 230, 0.623);
    color: #555;
    padding: 3px 8px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    z-index: 2;
    flex-shrink: 0;
    border: none;
    max-width: 48%;
}

.company-name {
    margin-right: 10px;
    margin-top: 0;
}

.company-name-input {
    display: block;
    border: none;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 24px;
    margin-right: 10px;
    margin-top: 14.92px;
    margin-bottom: 14.92px;
    margin-left: -5px;
    width: 100%;
    background-color: rgba(230, 230, 230, 0.623);
    padding: 5px;
    border-radius: 8px;
}

.company-description {
    margin-top: 5px;
    color: #555;
    font-size: medium;
}

.company-description-input {
    margin-top: 5px;
    color: #555;
    font-size: medium;
    border: none;
    margin-top: -1px;
    margin-left: -7px;
    background-color: rgba(230, 230, 230, 0.623);
    padding: 5px;
    border-radius: 8px;
}
