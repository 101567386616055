.summary {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
}

.summary-container {
    margin-top: 15px;
    display: flex;
    flex-direction: column; 
    gap: 12px;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    border: 1px solid transparent;
}

.summary-entry {
    padding: 10px;
    border-radius: 7px;
    white-space: pre-wrap;
    text-align: justify;
}
