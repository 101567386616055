.hire-companies {
    width: 95%;
}

.info-button {
    border: 1px solid #0000001a;
    background-color: #ffffff;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    color: #00000066;
    padding: 1px 14px;
    width: auto;
    cursor: pointer;
}

.info-button:hover {
    background-color: #0000001a;
}

.info-button.active {
    border: 1px solid #007aff1a;
    background-color: #007aff1a;
    color: #007aff;
}
