.App {
    text-align: center;
}

.main-container {
    display: flex;
    height: calc(100vh - 114px);
    margin-top: 114px;
}

@media only screen and (max-width: 600px), screen and (max-height: 600px) {
    .main-container {
        margin-top: 101px;
        height: calc(100vh - 141px);
    }

    .main-content {
        width: 100% !important;
    }
}

.sidebar, .secondary-content {
    width: 24%;
    height: 100%;
}

.main-content {
    width: calc(52% - 2px);
    margin-left: 1px;
    margin-right: 1px;
    height: 100%;
    background-color: #FBFCFF;
    display: flex;
    justify-content: center;
    overflow: auto;
    overflow-x: hidden;
}
