.start {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    width: 100%;
    border-radius: 5px;
    font-weight: 500;
}

.explain {
    margin-top: 30px;
}

.upload-cv {
    margin-top: 45px;
}

.dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #00000008;
    transition: background-color 0.3s ease;
    border-radius: 5px;
    width: 75%;
    height: 150px;
    cursor: pointer;
    margin-top: 20px;
}

.dropzone .dropzone-span {
    color: #000000b3;
    font-weight: 400;
    font-size: 12px;
}

.dropzone:hover {
    background-color: #0000000F;
}

.dropzone img {
    margin-top: 35px;
}

.linkedin {
    margin-top: 20px;
}

.linkedin-tuto {
    margin-top: 10px;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
}

.manual {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 15px;
}

.start .hn-buttons-container {
    margin-bottom: 15px;
}