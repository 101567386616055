.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(128, 128, 128, 0.5);
    z-index: 600;
}

.parser-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 400px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
}

.delete-modal h2 {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}

.parser-spinner {
    position:relative;
    margin-top: 15px;
    margin-bottom: 15px;
}