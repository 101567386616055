.experience-entry {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 7px;
}

.experience-entry-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
}

.experience-title {
    font-size: 20px;
    margin-right: 10px;
    margin-top: 0;
    margin-bottom: 0px;
}

.experience-title span {
    font-weight: 400;
    font-size: 20px;
}

.experience-location {
    font-size: 16px;
    font-weight: 500;
    color: #000000B3;
    margin-bottom: 3px;
}

.experience-location span {
    font-weight: 400;
}

.experience-time {
    font-weight: 500;
    color: #000000B3;
    margin-bottom: 10px;
}

.experience-time span {
    font-weight: 400;
}

.experience-description {
    white-space: pre-line;
    color: #000000;
    font-size: 15px;
}
